import * as Sentry from '@sentry/vue';
import { captureException } from '@sentry/vue';
import { loadStripe } from '@stripe/stripe-js/pure';
import { readonly, ref } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

/** @typedef {import('vue-router').RouteLocationRaw} RouteLocationRaw */
/** @typedef {import('vue-router').RouteLocationNormalized} RouteLocationNormalized */

// View components imported lazily for code splitting
// https://router.vuejs.org/guide/advanced/lazy-loading.html
const AddressesSection = () => import('./views/customers-detail/addresses/AddressesSection.vue');
const AccessControlSection = () =>
    import('./views/customers-detail/access-control/AccessControlSection.vue');
const CommissionOptionsSection = () =>
    import('./views/categories-detail/commission-options/CommissionOptionsSection.vue');
const CatalogSettings = () => import('./views/settings/catalog-settings/CatalogSection.vue');
const CategoriesDetailView = () => import('./views/categories-detail/CategoriesDetailView.vue');
const CategoriesListView = () => import('./views/categories-list/CategoriesListView.vue');
const ChargesListView = () => import('./views/charges-list/ChargesListView.vue');
const CommissionHierarchySection = () =>
    import('./views/categories-detail/CommissionHierarchySection.vue');
const CommissionOverridesSection = () =>
    import('./views/customers-detail/commission-overrides/CommissionOverridesSection.vue');
const CustomersCheckoutView = () => import('./views/customers-checkout/CustomersCheckoutView.vue');
const CustomersCreateOrderView = () =>
    import('./views/customers-create-order/CustomersCreateOrderView.vue');
const CustomerSettings = () => import('./views/settings/customer-settings/CustomersSection.vue');
const CustomersDetailView = () => import('./views/customers-detail/CustomersDetailView.vue');
const CustomersListView = () => import('./views/customers-list/CustomersListView.vue');
const InfoSection = () => import('./views/customers-detail/info/InfoSection.vue');
const InsufficientPermissionsView = () => import('./views/InsufficientPermissionsView.vue');
const InvoicingSection = () => import('./views/customers-detail/invoicing/InvoicingSection.vue');
const LoginView = () => import('./views/LoginView.vue');
const NotFoundView = () => import('./views/NotFoundView.vue');
const OrderSettings = () => import('./views/settings/order-settings/OrdersSection.vue');
const OrdersDetailView = () => import('./views/orders-detail/OrdersDetailView.vue');
const OrderRefundView = () => import('./views/orders-refund/OrderRefundView.vue');
const OrdersListView = () => import('./views/orders-list/OrdersListView.vue');
const OrdersSection = () => import('./views/customers-detail/orders/OrdersSection.vue');
const PaymentMethodsSection = () =>
    import('./views/customers-detail/payment-methods/PaymentMethodsSection.vue');
const PaymentsCreateView = () => import('./views/payments-create/PaymentsCreateView.vue');
const PayoutsDetailView = () => import('./views/payouts-detail/PayoutsDetailView.vue');
const PayoutsListView = () => import('./views/payouts-list/PayoutsListView.vue');
const ProductsListView = () => import('./views/products-list/ProductsListView.vue');
const ReportView = () => import('./views/reports/ReportView.vue');
const LegacyReportsListView = () => import('./views/legacy-reports-list/LegacyReportsListView.vue');
const RepSettings = () => import('./views/settings/rep-settings/RepsSection.vue');
const RepsCommissionOverridesSection = () =>
    import('./views/reps-detail/commission-overrides/RepsCommissionOverridesSection.vue');
const RepsCommissionStatsSection = () =>
    import('./views/reps-detail/commission-stats/RepsCommissionStatsSection.vue');
const RepsDetailView = () => import('./views/reps-detail/RepsDetailView.vue');
const RepsInfoSection = () => import('./views/reps-detail/info/RepsInfoSection.vue');
const RepsListView = () => import('./views/reps-list/RepsListView.vue');
const SettingsView = () => import('./views/settings/SettingsView.vue');
const SubscriptionsSection = () =>
    import('./views/customers-detail/subscriptions/SubscriptionsSection.vue');
const UsersDetailView = () => import('./views/settings/users-detail/UsersDetailView.vue');
const UsersListView = () => import('./views/settings/users-list/UsersListView.vue');

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: { name: 'customers-list' },
        },
        {
            path: '/customers',
            name: 'customers-list',
            component: CustomersListView,
            meta: {
                requiredPermissions: ['read:customers'],
            },
        },
        {
            path: '/customers/:customerId',
            component: CustomersDetailView,
            props: ({ params: { customerId } }) => ({ customerId }),
            meta: {
                breadcrumbs: [
                    { name: 'Customers', route: { name: 'customers-list' } },
                    { name: 'Customer Details', route: '' },
                ],
                requiredPermissions: ['read:customers'],
            },
            children: [
                {
                    path: '',
                    name: 'customers-detail',
                    component: InfoSection,
                },
                {
                    path: 'commission-overrides',
                    name: 'customers-detail-commission-overrides',
                    component: CommissionOverridesSection,
                    meta: {
                        requiredPermissions: [
                            'read:commission_overrides',
                            'update:commission_overrides',
                            'read:categories',
                            'read:reps',
                        ],
                    },
                },
                {
                    path: 'orders',
                    name: 'customers-detail-orders',
                    component: OrdersSection,
                    meta: {
                        requiredPermissions: ['read:orders'],
                    },
                },
                {
                    path: 'subscriptions',
                    children: [
                        {
                            path: '',
                            name: 'customers-detail-subscriptions',
                            component: SubscriptionsSection,
                            meta: {
                                requiredPermissions: ['read:subscriptions'],
                            },
                        },
                        {
                            path: '/customers/:customerId/subscriptions/:subscriptionId',
                            name: 'customers-detail-subscriptions-detail',
                            component: SubscriptionsSection,
                            meta: {
                                requiredPermissions: ['read:subscriptions'],
                            },
                        },
                    ],
                },
                {
                    path: 'payment-methods',
                    name: 'customers-detail-payment-methods',
                    component: PaymentMethodsSection,
                    meta: {
                        requiredPermissions: ['read:payment_methods'],
                    },
                },
                {
                    path: 'addresses',
                    name: 'customers-detail-addresses',
                    component: AddressesSection,
                    meta: {
                        requiredPermissions: ['read:customer_addresses'],
                    },
                },
                {
                    path: 'access-control',
                    name: 'customers-detail-access-control',
                    component: AccessControlSection,
                    meta: {
                        requiredPermissions: ['read:users', 'update:users'],
                    },
                },
                {
                    path: 'invoicing',
                    name: 'customer-detail-invoicing',
                    component: InvoicingSection,
                    meta: {
                        requiredFeatures: ['terms'],
                        requiredPermissions: ['read:terms'],
                    },
                },
            ],
        },
        {
            path: '/subscription-payment-method-callback',
            name: 'subscription-payment-method-callback',
            meta: {
                requiredPermissions: ['update:subscriptions'],
            },
            async beforeEnter(to) {
                const { setup_intent_client_secret, subscriptionId } = to.query;

                const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
                const { setupIntent, error } = await stripe.retrieveSetupIntent(
                    setup_intent_client_secret,
                );

                if (error) {
                    Sentry.captureException(error);
                    return { name: 'customers-list' };
                }

                if (!setupIntent.payment_method) {
                    Sentry.captureMessage('payment method not included in setup intent response');
                    return { name: 'customers-list' };
                }

                try {
                    const { updateSubscription } = await import('./services/subscriptions');
                    const { data } = updateSubscription(subscriptionId, {
                        stripe_payment_method_id: setupIntent.payment_method,
                    });
                    const subscription = await data;

                    return {
                        name: 'customers-detail-subscriptions-detail',
                        params: {
                            customerId: subscription.customer_id,
                            subscriptionId: subscription.id,
                        },
                    };
                } catch (error) {
                    Sentry.captureException(error);
                    return { name: 'customers-list' };
                }
            },
        },
        {
            path: '/customers/:customerId/checkout/:checkoutId',
            name: 'customers-checkout',
            component: CustomersCheckoutView,
            props: true,
            meta: {
                breadcrumbs: [
                    { name: 'Customers', route: { name: 'customers-list' } },
                    { name: 'Customer Details', route: { name: 'customers-detail' } },
                    { name: 'New Order', route: { name: 'customers-create-order' } },
                ],
                requiredPermissions: [
                    'create:orders',
                    'read:customers',
                    'read:customer_addresses',
                    'read:payment_methods',
                    'read:products',
                ],
            },
        },
        {
            path: '/customers/:customerId/create-order',
            name: 'customers-create-order',
            component: CustomersCreateOrderView,
            props: true,
            meta: {
                breadcrumbs: [
                    { name: 'Customers', route: { name: 'customers-list' } },
                    { name: 'Customer Details', route: { name: 'customers-detail' } },
                    { name: 'New Order', route: '' },
                ],
                requiredPermissions: [
                    'create:orders',
                    'read:customers',
                    'read:customer_addresses',
                    'read:payment_methods',
                    'read:products',
                ],
            },
        },
        {
            path: '/orders',
            name: 'orders-list',
            component: OrdersListView,
            meta: {
                requiredPermissions: ['read:orders'],
            },
        },
        {
            path: '/orders/:orderId',
            name: 'orders-detail',
            component: OrdersDetailView,
            props: true,
            meta: {
                breadcrumbs: [
                    { name: 'E-Commerce Orders', route: { name: 'orders-list' } },
                    { name: 'Order Details', route: '' },
                ],
                requiredPermissions: ['read:orders', 'read:categories'],
            },
        },
        {
            path: '/orders/:orderId/new-shipment',
            name: 'shipments-create',
            redirect: { name: 'shipments-create-addresses' },
            component: () => import('./views/shipments-create/ShipmentsCreateView.vue'),
            props: true,
            meta: {
                breadcrumbs: [
                    { name: 'E-Commerce Orders', route: { name: 'orders-list' } },
                    { name: 'Order Details', route: { name: 'orders-detail' } },
                    { name: 'Create Shipment', route: '' },
                ],
                requiredPermissions: ['read:orders', 'read:shipments', 'create:shipments'],
            },
            children: [
                {
                    path: 'addresses',
                    name: 'shipments-create-addresses',
                    component: () => import('./views/shipments-create/AddressesSection.vue'),
                },
                {
                    path: 'add-items',
                    name: 'shipments-create-items',
                    component: () => import('./views/shipments-create/AddItemsSection.vue'),
                },
                {
                    path: 'shipping-options',
                    name: 'shipments-create-options',
                    component: () => import('./views/shipments-create/ShippingOptionsSection.vue'),
                },
                {
                    path: 'review',
                    name: 'shipments-create-review',
                    component: () => import('./views/shipments-create/ReviewSection.vue'),
                },
            ],
        },
        {
            path: '/orders/:orderId/refund',
            name: 'order-refund',
            component: OrderRefundView,
            meta: {
                breadcrumbs: [
                    { name: 'E-Commerce Orders', route: { name: 'orders-list' } },
                    { name: 'Order Details', route: { name: 'orders-detail' } },
                    { name: 'Refund Order', route: { name: 'order-refund' } },
                ],
                requiredPermissions: ['refund:orders', 'read:orders'],
            },
            props: true,
        },
        {
            path: '/sales-reps',
            name: 'reps-list',
            component: RepsListView,
            meta: {
                requiredPermissions: ['read:reps', 'read:categories'],
            },
        },
        {
            path: '/sales-reps/:repId',
            component: RepsDetailView,
            props: ({ params: { repId } }) => ({ repId }),
            meta: {
                breadcrumbs: [
                    { name: 'Sales Reps', route: { name: 'reps-list' } },
                    { name: 'Rep Details', route: '' },
                ],
                requiredPermissions: ['read:reps'],
            },
            children: [
                {
                    path: '',
                    name: 'reps-detail',
                    component: RepsInfoSection,
                },
                {
                    path: 'commission-overrides',
                    name: 'reps-detail-commission-overrides',
                    component: RepsCommissionOverridesSection,
                    meta: {
                        requiredPermissions: [
                            'read:categories',
                            'read:commission_overrides',
                            'update:commission_overrides',
                        ],
                    },
                },
                {
                    path: 'commission-stats',
                    name: 'reps-detail-commission-stats',
                    component: RepsCommissionStatsSection,
                    meta: {
                        requiredPermissions: ['read:categories', 'read:commission_overrides'],
                    },
                },
            ],
        },
        {
            path: '/products',
            name: 'products-list',
            component: ProductsListView,
            meta: {
                requiredPermissions: ['read:products', 'read:categories'],
            },
        },
        {
            path: '/categories',
            name: 'categories-list',
            component: CategoriesListView,
            meta: {
                requiredPermissions: ['read:categories', 'read:reps'],
            },
        },
        {
            path: '/categories/:categoryId',
            name: 'categories-detail',
            component: CategoriesDetailView,
            props: ({ params: { categoryId } }) => ({ categoryId }),
            meta: {
                breadcrumbs: [
                    { name: 'Categories', route: { name: 'categories-list' } },
                    { name: 'Category Details', route: '' },
                ],
                requiredPermissions: ['read:categories', 'read:reps'],
            },
            children: [
                {
                    path: '',
                    name: 'commission-hierarchy',
                    component: CommissionHierarchySection,
                },
                {
                    path: 'options',
                    name: 'commission-options',
                    component: CommissionOptionsSection,
                },
            ],
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView,
            async beforeEnter() {
                const { default: auth } = await import('./auth');

                await auth.isReady();
                if (auth.isAuthenticated.value) {
                    return '/';
                }
            },
            meta: {
                public: true,
            },
        },
        {
            path: '/authorize',
            name: 'authorize-redirect',
            component: null,
            async beforeEnter() {
                const { default: auth } = await import('./auth');
                const { default: tenant } = await import('./tenant');

                await tenant.isReady();

                const searchParams = new URLSearchParams(window.location.search);

                auth.logInWithRedirect({
                    organization: tenant.info.value.auth0_org_id,
                    invitation: searchParams.get('invitation') || undefined,
                });
            },
            meta: {
                public: true,
            },
        },
        {
            path: '/authorized',
            name: 'authorized-callback',
            component: null,
            async beforeEnter() {
                const { default: auth } = await import('./auth');

                try {
                    const { appState } = await auth.handleRedirectCallback();
                    return appState?.returnToPath || '/';
                } catch (error) {
                    captureException(error);
                    return '/';
                }
            },
            meta: {
                public: true,
            },
        },
        {
            path: '/payments',
            name: 'charges-list',
            component: ChargesListView,
            meta: {
                requiredPermissions: ['read:charges'],
            },
        },
        {
            path: '/payments/create',
            name: 'payments-create',
            component: PaymentsCreateView,
            async beforeEnter(to) {
                // Load the Stripe JS lib during navigation and access in the component
                // https://github.com/vuejs/vue-router/issues/1923#issuecomment-865019686
                to.meta._stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
            },
            meta: {
                breadcrumbs: [
                    { name: 'Payments', route: { name: 'charges-list' } },
                    { name: 'New Payment', route: '' },
                ],
                requiredPermissions: ['create:payments', 'read:customers'],
            },
        },
        {
            path: '/payments/create-callback',
            name: 'payments-create-callback',
            async beforeEnter(to) {
                const { waitForPayment } = await import('./views/charges-list/callback');

                const { payment_session, redirect_status = 'succeeded' } = to.query;

                if (redirect_status === 'succeeded') {
                    // Poll our server for a few seconds to wait for the Stripe webhook to land.
                    try {
                        await waitForPayment(payment_session, 10.0);
                    } catch (error) {
                        Sentry.captureException(error);
                    }

                    return { name: 'charges-list', query: { callback_payment: payment_session } };
                } else {
                    Sentry.captureMessage('redirect_status is not succeeded');
                    return { name: 'charges-list', query: { callback_error: true } };
                }
            },
        },
        {
            path: '/payouts',
            name: 'payouts-list',
            component: PayoutsListView,
            meta: {
                requiredPermissions: ['read:payouts'],
            },
        },
        {
            path: '/payouts/:payoutId',
            name: 'payouts-detail',
            component: PayoutsDetailView,
            props: true,
            meta: {
                breadcrumbs: [
                    { name: 'Payouts', route: { name: 'payouts-list' } },
                    { name: 'Payout Details', route: '' },
                ],
                requiredPermissions: ['read:payouts'],
            },
        },
        {
            path: '/legacy-reports',
            name: 'legacy-reports-list',
            component: LegacyReportsListView,
            meta: {
                requiredPermissions: ['run:reports'],
            },
        },
        {
            path: '/reports',
            name: 'reports-list',
            component: ReportView,
            meta: {
                requiredPermissions: ['run:reports'],
            },
            redirect: { name: 'commission-report' },
            children: [
                {
                    path: 'commission',
                    name: 'commission-report',
                    component: () => import('./views/reports/CommissionReport.vue'),
                },
                {
                    path: 'customers',
                    name: 'customers-report',
                    component: () => import('./views/reports/CustomersReport.vue'),
                },
                {
                    path: 'orders',
                    name: 'orders-report',
                    component: () => import('./views/reports/OrdersReport.vue'),
                },
                {
                    path: 'refunds',
                    name: 'refunds-report',
                    component: () => import('./views/reports/RefundsReport.vue'),
                },
                {
                    path: 'subscriptions',
                    name: 'subscriptions-report',
                    component: () => import('./views/reports/SubscriptionsReport.vue'),
                },
                {
                    path: 'subscription-line-items',
                    name: 'subscription-line-items-report',
                    component: () => import('./views/reports/SubscriptionLineItemsReport.vue'),
                },
            ],
        },
        {
            path: '/settings',
            component: SettingsView,
            meta: {
                requiredPermissions: ['read:settings', 'read:reps', 'read:customers'],
            },
            children: [
                {
                    path: '',
                    name: 'settings-customer',
                    component: CustomerSettings,
                },
                {
                    path: 'rep-settings',
                    name: 'settings-rep',
                    component: RepSettings,
                },
                {
                    path: 'order-settings',
                    name: 'settings-order',
                    component: OrderSettings,
                },
                {
                    path: 'catalog-settings',
                    name: 'settings-catalog',
                    component: CatalogSettings,
                },
                {
                    path: 'users',
                    name: 'users-list',
                    component: UsersListView,
                    meta: {
                        requiredPermissions: ['read:users'],
                    },
                },
                {
                    path: 'users/:userId',
                    name: 'users-detail',
                    component: UsersDetailView,
                    props: true,
                    meta: {
                        requiredPermissions: ['read:users'],
                    },
                },
            ],
        },
        {
            path: '/insufficient-permissions',
            name: 'insufficient-permissions',
            component: InsufficientPermissionsView,
        },
        {
            // Catch-all not found route
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: NotFoundView,
        },
    ],
});

export default router;

const _isNavigating = ref(true);
router.isReady().then(() => {
    _isNavigating.value = false;
});
router.beforeEach(() => {
    _isNavigating.value = true;
});
router.afterEach(() => {
    _isNavigating.value = false;
});

export const isNavigating = readonly(_isNavigating);

router.onError((error, to) => {
    _isNavigating.value = false;
    window.alert(
        `
        An error occurred while trying to navigate to the page "${to.path}". Please
        check your device's network connection and try reloading the page.
    `
            .trim()
            .replace(/\s+/g, ' '),
    );
});

/**
 * Returns the tenant features required for a given route
 * @param {RouteLocationRaw|RouteLocationNormalized} route Route object, will be resolved if needed
 * @returns {string[]} Required feature strings
 */
export const getFeaturesForRoute = (route) => {
    if (!('matched' in route)) {
        route = router.resolve(route);
    }

    return route.matched
        .flatMap((route) => route.meta.requiredFeatures)
        .filter((feature) => typeof feature === 'string' && feature.length > 0);
};

/**
 * Returns the permissions required for a given route
 * @param {RouteLocationRaw|RouteLocationNormalized} route Route object, will be resolved if needed
 * @returns {string[]} Required permission strings
 */
export const getPermissionsForRoute = (route) => {
    if (!('matched' in route)) {
        route = router.resolve(route);
    }

    return route.matched
        .flatMap((route) => route.meta.requiredPermissions)
        .filter((permission) => typeof permission === 'string' && permission.length > 0);
};

// Auth guard
router.beforeEach(async (to) => {
    if (to.meta.public) return;

    const { default: auth } = await import('./auth');
    await auth.isReady();

    // Check if the user is signed in
    if (!auth.isAuthenticated.value) {
        return { name: 'login', query: { 'return-to': to.fullPath } };
    }

    // Check if the tenant has the required features
    const tenant = (await import('./tenant')).default;
    await tenant.isReady();
    if (!tenant.hasFeature(...getFeaturesForRoute(to))) {
        return { name: 'insufficient-permissions' };
    }

    // Check if the user has the required permissions
    if (!auth.userCan(...getPermissionsForRoute(to))) {
        return { name: 'insufficient-permissions' };
    }
});

/**
 * Gets the absolute URL for a route relative to ``window.location``
 * @param {RouteLocationRaw} route A route location
 * @returns {string} An absolute URL
 */
export const resolveAbsoluteURL = (route) => {
    const { href } = router.resolve(route);
    return new URL(href, window.location.origin).href;
};

// Track page change events with umami
router.afterEach((to, from) => {
    const domainRegex = /\.?velocity\.medshift\.com$/;

    if (window.umami && domainRegex.test(location.host) && to.path !== from.path) {
        const referrer = resolveAbsoluteURL(from.path);
        window.umami.trackView(to.path, referrer);
    }
});
