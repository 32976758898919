<script setup>
    function reloadPage() {
        window.location.reload();
    }
</script>

<template>
    <Modal is-open>
        <div class="space-y-2 pb-2 text-sm text-gray-600">
            <p>
                Something went wrong while trying to load this page. Please check your network
                connection and try reloading the page.
            </p>
            <p>If you continue to encounter this issue, please contact our support team.</p>
        </div>

        <template #actions>
            <Button @click="reloadPage()"> Reload Page </Button>
        </template>
    </Modal>
</template>
