import { Auth0AuthenticationError } from './errors';

const base64URLEncode = (uint8Array) =>
    window
        .btoa(String.fromCharCode(...uint8Array))
        .replaceAll('+', '-')
        .replaceAll('/', '_')
        .replace(/=+$/, '');

/**
 * Generate a random string usable for the 'state' parameter
 * @see https://auth0.com/docs/secure/attack-protection/state-parameters
 */
export const generateState = () => {
    const STATE_CHARS = 32;

    const buffer = new Uint8Array(Math.ceil(STATE_CHARS * 0.75));
    window.crypto.getRandomValues(buffer);

    // We'll use base64url for the character set, though we can use any opaque string
    return base64URLEncode(buffer).slice(0, STATE_CHARS);
};

/**
 * Generates random PKCE code verifier and code challenge values
 * @see https://datatracker.ietf.org/doc/html/rfc7636
 */
export const generateCodeVerifierAndChallenge = async () => {
    const VERIFIER_BYTES = 32;

    // The verifier is a random string of characters from a set in the spec (we just use base64url)
    // We'll present this at the end of login to prove we initiated the process
    const buffer = new Uint8Array(VERIFIER_BYTES);
    window.crypto.getRandomValues(buffer);
    const verifier = base64URLEncode(buffer);

    // The challenge is a base64url-encoded SHA-256 hash of the verifier's ASCII representation
    // We'll initially present this when initiating the login process
    const verifierBytes = Uint8Array.from(verifier, (char) => char.charCodeAt(0));
    const digest = new Uint8Array(await window.crypto.subtle.digest('SHA-256', verifierBytes));
    const challenge = base64URLEncode(digest);

    return { verifier, challenge, method: 'S256' };
};

/** Helper function for converting Auth0 callback-based async to Promises */
export const auth0Promisify = (fn) =>
    function (...args) {
        return new Promise((resolve, reject) => {
            const callback = (err, res) => {
                err ? reject(new Auth0AuthenticationError(err)) : resolve(res);
            };
            fn.call(this, ...args, callback);
        });
    };

/** Helper class for storing and retrieving values from localStorage */
export class LocalStorage {
    constructor(namespace) {
        this.namespace = namespace;
    }

    _namespaced(key) {
        return `${this.namespace}::${key}`;
    }

    /** Saves a value with a given key */
    store(key, value, { ttl = undefined } = {}) {
        const expiryTimestamp = ttl > 0 ? Date.now() + ttl * 1000 : undefined;

        const dataString = JSON.stringify({ val: value, exp: expiryTimestamp });
        localStorage.setItem(this._namespaced(key), dataString);
    }

    /** Retrieves a value by its key */
    load(key) {
        const storedValue = localStorage.getItem(this._namespaced(key));
        if (storedValue == null) return null;

        try {
            const { val, exp } = JSON.parse(storedValue);

            // Handle expired entries
            if (exp && Date.now() > exp) {
                this.delete(key);
                return null;
            }

            return val;
        } catch (error) {
            // Handle malformed data in localstorage
            if (error instanceof SyntaxError || error instanceof TypeError) {
                this.delete(key);
                return null;
            }

            throw error;
        }
    }

    /** Deletes a value by its key */
    delete(key) {
        localStorage.removeItem(this._namespaced(key));
    }

    /** Clears any expired values from storage */
    clean() {
        Object.keys(localStorage)
            .filter((fullKey) => fullKey.startsWith(this._namespaced(''))) // Only look at our namespaced keys
            .forEach((fullKey) => {
                const storedValue = localStorage.getItem(fullKey);
                if (!storedValue) return;

                const { exp } = JSON.parse(storedValue);
                if (exp && Date.now() > exp) {
                    localStorage.removeItem(fullKey);
                }
            });
    }
}

/** Helper class for storing and retrieving JWKS objects from localStorage */
export class JwksCache extends LocalStorage {
    constructor(namespace, ttl) {
        super(namespace);
        this.ttl = ttl;
    }
    get(key) {
        return this.load(key);
    }

    has(key) {
        return !!this.load(key);
    }

    set(key, keyinfo) {
        this.store(key, keyinfo, { ttl: this.ttl });
    }
}
