import { setUser } from '@sentry/vue';
import { Authentication, WebAuth } from 'auth0-js';
import IdTokenVerifier from 'idtoken-verifier';
import { computed, reactive, toRefs, watchEffect } from 'vue';
import { AuthCallbackError, AuthenticationExpiredError, NotAuthenticatedError } from './errors';
import {
    JwksCache,
    LocalStorage,
    auth0Promisify,
    generateCodeVerifierAndChallenge,
    generateState,
} from './helpers';

const DEFAULT_SCOPES = 'openid profile email offline_access';
const RESPONSE_TYPE = 'code';

const CUSTOM_CLAIM_NAMESPACE = 'https://medshift.com';

export const createAuth0 = ({ domain, clientId, apiAudience, redirectCallbackUrl }) => {
    const auth0 = new Authentication({
        domain: domain,
        clientID: clientId,
    });

    const webAuth = new WebAuth({
        domain: domain,
        clientID: clientId,
    });

    const tokenVerifier = new IdTokenVerifier({
        issuer: `https://${domain}/`,
        audience: clientId, // This is separate from the API audience
        jwksCache: new JwksCache('JwksCache', 60 * 60 * 24 * 7),
    });

    const storage = new LocalStorage('Auth0');
    storage.clean();

    /**
     * @typedef {object} AuthData
     * @property {string} [idToken]
     * @property {string} [accessToken]
     * @property {string} [refreshToken]
     * @property {string} [scope]
     * @property {number} [expirationTimestamp]
     */

    /**
     * Basic authentication data, will be stored/loaded to restore sessions
     * @type {AuthData|undefined}
     */
    let authData = undefined;

    const state = reactive({
        isLoading: true,
        user: undefined,
        isAuthenticated: computed(() => !!state.user),
        permissions: computed(() => {
            const permissions = state.user?.[CUSTOM_CLAIM_NAMESPACE + '/permissions'];
            return permissions ? new Set(permissions) : undefined;
        }),
        dbConnection: computed(() => state.user?.[CUSTOM_CLAIM_NAMESPACE + '/db_connection']),
        callbackError: null,
    });

    async function _handleAuthResult({ idToken, accessToken, refreshToken, expiresIn, scope }) {
        // Verify and decode the ID token
        const idTokenPayload = await auth0Promisify(tokenVerifier.verify).call(
            tokenVerifier,
            idToken,
        );

        // Enrich Sentry events
        setUser({
            username: idTokenPayload.sub,
            email: idTokenPayload.email,
        });

        // Get the lifetime of whichever token will expire first
        const idTokenExp = idTokenPayload.exp;
        const accessTokenExp = Math.floor(Date.now() / 1000) + expiresIn;
        const expirationTimestamp = Math.min(idTokenExp, accessTokenExp);

        authData = { expirationTimestamp, idToken, accessToken, refreshToken, scope };
        state.user = idTokenPayload;

        storage.store(`authentication.${clientId}`, { refreshToken, scope });
    }

    async function _performRefresh() {
        state.isLoading = true;

        try {
            const authResult = await auth0Promisify(auth0.oauthToken).call(auth0, {
                grantType: 'refresh_token',
                refreshToken: authData.refreshToken,
            });

            await _handleAuthResult(authResult);
        } catch (error) {
            throw error.code === 'invalid_grant'
                ? new AuthenticationExpiredError(error.description)
                : error;
        } finally {
            state.isLoading = false;
        }
    }

    /** Perform a refresh token exchange */
    async function forceRefresh() {
        if (!authData) throw new NotAuthenticatedError();

        await navigator.locks.request(`Auth0::oauthToken.${clientId}`, async () => {
            await _performRefresh();
        });
    }

    /** Performs a refresh token exchange if the stored authentication data is expired */
    async function refreshIfNeeded() {
        if (!authData) throw new NotAuthenticatedError();

        const getIsExpired = () => authData.expirationTimestamp <= Date.now() / 1000;

        // Initial check to avoid acquiring the lock
        let isExpired = getIsExpired();
        if (isExpired) {
            // Wait to acquire the lock and then check if we're still expired
            await navigator.locks.request(`Auth0::oauthToken.${clientId}`, async () => {
                isExpired = getIsExpired();
                if (isExpired) {
                    await _performRefresh();
                }
            });
        }

        return isExpired;
    }

    async function restorePreviousSession() {
        const storedAuth = storage.load(`authentication.${clientId}`);
        if (!storedAuth) return;

        // Check if the stored auth data matches our current expectations
        if (storedAuth.scope !== DEFAULT_SCOPES) {
            storage.delete(`authentication.${clientId}`);
            return;
        }

        authData = storedAuth;

        try {
            await forceRefresh();
        } catch (error) {
            if (error instanceof AuthenticationExpiredError) {
                authData = undefined;
                storage.delete(`authentication.${clientId}`);
            } else {
                throw error;
            }
        }
    }

    restorePreviousSession().finally(() => (state.isLoading = false));

    return {
        ...toRefs(state),

        refreshIfNeeded,
        forceRefresh,

        /**
         * Dispatch password reset request to Auth0 for the current user and database connection
         * @returns {Promise<void>} Promise of the Auth0 password reset request
         */
        async resetPassword() {
            await auth0Promisify(webAuth.changePassword).call(webAuth, {
                email: state.user.email,
                connection: state.dbConnection,
            });
        },

        /**
         * @typedef {object} LogInWithRedirectOptions
         * @param {string} [options.organization] Auth0 organization ID
         * @param {string} [options.invitation] Ticket ID of an Auth0 organization invitation
         * @param {any} [options.appState] Arbitrary JSON-encodable data that will be available in the login callback
         */

        /**
         * Redirect to the Auth0-hosted login page
         * @param {LogInWithRedirectOptions} [options]
         */
        async logInWithRedirect({ organization, invitation, appState = null } = {}) {
            const state = generateState();
            const { verifier, challenge, method } = await generateCodeVerifierAndChallenge();

            storage.store(`transaction.${state}`, { state, appState, verifier }, { ttl: 10 * 60 });

            window.location.assign(
                auth0.buildAuthorizeUrl({
                    scope: DEFAULT_SCOPES,
                    responseType: RESPONSE_TYPE,
                    redirectUri: redirectCallbackUrl,
                    audience: apiAudience,
                    state,
                    codeChallenge: challenge,
                    codeChallengeMethod: method,
                    organization,
                    invitation,
                }),
            );
        },

        /**
         * @typedef {object} LoginCallbackResult
         * @property {any} appState Data provided to `logInWithRedirect()`
         */

        /**
         * Parse the current page's fragment after an Auth0 callback to sign the user in
         * @returns {Promise<LoginCallbackResult>} Result of the login callback
         */
        async handleRedirectCallback() {
            state.isLoading = true;

            try {
                const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

                // Handle error responses from Auth0
                if (queryParams.error) {
                    state.callbackError = new AuthCallbackError(
                        queryParams.error,
                        queryParams.error_description,
                    );
                    throw state.callbackError;
                }

                const { appState, verifier } =
                    storage.load(`transaction.${queryParams.state}`) ?? {};

                // Exchange our code for auth tokens
                let authResult;
                try {
                    authResult = await auth0Promisify(auth0.oauthToken).call(auth0, {
                        grantType: 'authorization_code',
                        code: queryParams.code,
                        codeVerifier: verifier ?? '',
                        redirectUri: window.encodeURI(redirectCallbackUrl), // TODO: does auth0.js encode this for us?
                    });
                } catch (error) {
                    state.callbackError = new AuthCallbackError(error.code, error.description);
                    throw state.callbackError;
                }

                storage.delete(`transaction.${queryParams.state}`);
                await _handleAuthResult(authResult);

                return { appState };
            } finally {
                state.isLoading = false;
            }
        },

        /** Immediately redirect the user to Auth0's logout page and clear the stored session */
        logOut({ returnToUrl }) {
            if (!state.isAuthenticated) throw new NotAuthenticatedError();

            state.isLoading = true;

            storage.delete(`authentication.${clientId}`);

            window.location.assign(
                auth0.buildLogoutUrl({
                    clientID: clientId,
                    returnTo: returnToUrl,
                }),
            );
        },

        /** A promise that resolves when `isLoading` becomes `false` */
        isReady() {
            let cancel;
            return new Promise((resolve) => {
                cancel = watchEffect(() => {
                    if (!state.isLoading) resolve();
                });
            }).then(() => void cancel());
        },

        /** Returns an access token for the API specified by `apiAudience` */
        async getAccessToken() {
            await refreshIfNeeded();

            if (!state.isAuthenticated) throw new NotAuthenticatedError();

            return authData.accessToken;
        },

        /** Checks if the user has all of the specified permissions */
        userCan(...permissions) {
            const userPermissions = state.permissions ?? new Set();
            return permissions.every((p) => userPermissions.has(p));
        },

        /** Checks if the specified Auth0 ID matches the authenticated user's Auth0 ID */
        userIs(id) {
            return id === state.user?.sub;
        },

        install(app) {
            // Makes this object available in components with `inject('auth')`
            app.provide('auth', this);
        },
    };
};
