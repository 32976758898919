import { inject } from 'vue';
import { getTenant } from './services/tenant';
import { useAsyncState } from './utils';

/**
 * @template T
 * @typedef {import('vue').Ref<T>} Ref<T>
 */

/**
 * @typedef {Object} TenantInfo
 * @property {string} display_name
 * @property {string} brand_color_hex
 * @property {string} logo_url
 * @property {string} auth0_org_id
 * @property {Object} features
 * @property {Object} shipengine_config
 * @property {Object} integrations
 */

const tenant = (() => {
    const state = useAsyncState(getTenant().data);

    return {
        /** @type {Ref<TenantInfo|undefined>} */
        info: state.value,
        /** @type {Ref<boolean>} */
        isLoading: state.isPending,
        /** @type {Ref<Error|undefined>} */
        error: state.error,

        isReady() {
            return state.promise.then(() => undefined);
        },
        hasFeature(...features) {
            return features.every((f) => !!state.value.value.features[f]);
        },
        hasIntegration(...integrations) {
            return integrations.every((i) => !!state.value.value.integrations[i]);
        },
        install(app) {
            // Makes this object available in components with `inject('tenant')`
            app.provide('tenant', this);
        },
    };
})();

export default tenant;

/** @returns {typeof tenant} */
export function useTenant() {
    return inject('tenant');
}
