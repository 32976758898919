<script setup>
    import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
    import { computed, reactive } from 'vue';
    import { useTenant } from '../../tenant';
    import NavIcons from './NavIcons.vue';
    import NavLinks from './NavLinks.vue';

    const tenant = reactive(useTenant());

    const brandColor = computed(() => (tenant.info ? '#' + tenant.info.brand_color_hex : null));
</script>

<template>
    <Popover
        as="nav"
        v-slot="{ open }"
        class="relative border-t-4 bg-slate-700"
        :style="{ borderTopColor: brandColor ?? 'transparent' }"
    >
        <!-- Navbar -->
        <div class="mx-auto flex h-16 max-w-6xl items-center px-4">
            <!-- Site Logo -->
            <div class="flex flex-shrink-0 items-center">
                <Spinner v-if="tenant.isLoading" size="sm" class="text-blue-300" />
                <img
                    v-else-if="tenant.info"
                    :src="tenant.info.logo_url"
                    :alt="tenant.info.display_name"
                    class="h-8 w-auto"
                />
            </div>

            <!-- Navigation Links -->
            <div v-if="tenant.info" class="mx-5 hidden space-x-2 md:flex">
                <NavLinks />
            </div>

            <!-- Navigation Icons -->
            <div class="ml-auto flex flex-shrink-0 items-center space-x-2">
                <NavIcons />

                <!-- Menu button on mobile -->
                <PopoverButton
                    class="rounded-md p-2 text-slate-300 hover:bg-slate-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500 md:hidden"
                >
                    <span class="sr-only">Open menu</span>
                    <FontAwesomeIcon :icon="open ? faTimes : faBars" size="lg" fixed-width />
                </PopoverButton>
            </div>
        </div>

        <!-- Mobile Nav Popover -->
        <PopoverPanel class="absolute left-0 right-0 z-30 bg-slate-700 md:hidden">
            <!-- Wrap the contents in a PopoverButton so clicking inside the popover also dismisses it -->
            <PopoverButton as="div" class="space-y-1 px-2 pb-3 pt-2">
                <div class="flex flex-col space-y-1">
                    <NavLinks />
                </div>
            </PopoverButton>
        </PopoverPanel>
    </Popover>

    <!-- Breadcrumbs -->
    <div v-if="$slots.breadcrumbs" class="border-b border-slate-200 bg-slate-100">
        <div class="mx-auto max-w-6xl px-4 py-3">
            <nav aria-label="Breadcrumb">
                <slot name="breadcrumbs" />
            </nav>
        </div>
    </div>

    <!-- Main Content -->
    <main class="mx-auto max-w-6xl px-4 pb-12 pt-10 md:pb-16">
        <slot />
    </main>
</template>
