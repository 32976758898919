<script setup>
    import { useRoute } from 'vue-router';
    import FatalErrorModal from './components/FatalErrorModal.vue';
    import Breadcrumbs from './components/layout/Breadcrumbs.vue';
    import Layout from './components/layout/Layout.vue';
    import { useTenant } from './tenant';

    const { error: tenantError } = useTenant();

    const route = useRoute();
</script>

<template>
    <Layout>
        <template #default>
            <RouterView />
        </template>
        <template #breadcrumbs v-if="route.meta.breadcrumbs">
            <Breadcrumbs :pages="route.meta.breadcrumbs" />
        </template>
    </Layout>

    <FatalErrorModal v-if="!!tenantError" />
</template>
