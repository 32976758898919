<script setup>
    import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
    import { computed, inject } from 'vue';
    import { useLink } from 'vue-router';
    import { getPermissionsForRoute } from '../../router';
    import NavDropdownContainer from './NavDropdownContainer.vue';
    import NavLinkItem from './NavLinkItem.vue';

    const { userCan } = inject('auth');

    const props = defineProps({
        name: { type: String, required: true },
        links: { type: Array, default: () => [] },
    });

    const anyLinkPermissioned = computed(() =>
        props.links.some(({ route }) => userCan(...getPermissionsForRoute(route))),
    );
    const isActive = computed(() =>
        props.links.some(({ name, route }) => useLink({ name, to: route }).isActive.value),
    );
</script>

<template>
    <Popover v-if="$slots.default || anyLinkPermissioned" class="relative">
        <PopoverButton
            class="flex w-full items-center rounded-md px-3 py-2 text-left text-sm font-medium text-slate-100 hover:bg-slate-600 hover:text-white md:text-center"
            :class="{ 'bg-slate-800': isActive }"
        >
            <template #default="{ open }">
                {{ name }}
                <FontAwesomeIcon
                    :icon="open ? faAngleUp : faAngleDown"
                    class="ml-2 text-xs text-gray-300"
                />
            </template>
        </PopoverButton>
        <transition
            enter-active-class="transition duration-150 ease-out"
            enter-from-class="-translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="-translate-y-1 opacity-0"
        >
            <PopoverPanel
                class="relative z-20 mt-1 min-w-full max-sm:ml-1 md:absolute"
                v-slot="{ open, close }"
            >
                <NavDropdownContainer :is-open="open" @close="close">
                    <slot>
                        <NavLinkItem v-for="link in links" :key="link.name" v-bind="link" />
                    </slot>
                </NavDropdownContainer>
            </PopoverPanel>
        </transition>
    </Popover>
</template>
