import { format, parse } from 'date-fns';
import { computed, onUnmounted, readonly, ref, unref, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

/**
 * @template T
 * @typedef {import('vue').Ref<T>} Ref<T>
 */

/**
 * The timezone for all order subscriptions dates
 * @type {string}
 */
export const SUBSCRIPTIONS_TZ = '-05:00';

/**
 * The timezone for all order terms dates
 * @type {string}
 */
export const TERMS_TZ = '-05:00';

/**
 * Formats the input as US dollars (`3.5` => `'$3.50'`)
 * @param {Number} dollars Amount of currency in dollars
 * @returns {String} Formatted string
 */
export const formatDollars = (dollars) =>
    (+dollars || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

/**
 * Formats the input as US cents (`350` => `'$3.50'`)
 * @param {Number} cents Amount of currency in cents
 * @returns {String} Formatted string
 */
export const formatCents = (cents) => formatDollars(cents / 100);

/**
 * Converts dollars to cents, rounding to the nearest whole cent
 * @param {number|string} dollars Amount of currency in dollars
 * @returns {number} Amount of currency in cents
 */
export const dollarsToCents = (dollars) => Math.round(parseFloat(dollars) * 100);

/**
 * Shortcut for creating a settable computed property for v-model
 * @param {string} propName - The name of the prop
 * @param {Object} context - The props object and emit function
 */
export const useVModelRef = (propName, { props, emit }) =>
    computed({
        get: () => props[propName],
        set: (value) => emit(`update:${propName}`, value),
    });

/**
 * @template T
 * @typedef {Object} AsyncState<T>
 * @property {Ref<T | undefined>} value The value provided if the promise fulfilled
 * @property {Ref<any>} error The reason for the promise's rejection
 * @property {Ref<boolean>} isPending If the promise has not yet fulfilled or rejected
 * @property {Ref<boolean>} isFulfilled If the promise completed successfully
 * @property {Ref<boolean>} isRejected If the promise failed to complete
 * @property {Promise<T>} promise The original promise
 */

/**
 * Create reactive state for a promise
 * @template T
 * @param {Promise<T>} promise A promise
 * @returns {AsyncState<T>} Refs relating to the state of the promise
 */
export const useAsyncState = (promise) => {
    promise = Promise.resolve(promise); // Make sure we're dealing with a promise

    const value = ref(undefined);
    const error = ref(undefined);
    const isPending = ref(true);
    const isFulfilled = ref(false);
    const isRejected = ref(false);

    promise.then(
        (val) => {
            value.value = val;
            isPending.value = false;
            isFulfilled.value = true;
        },
        (err) => {
            error.value = err;
            isPending.value = false;
            isRejected.value = true;
        },
    );

    return { value, error, isPending, isFulfilled, isRejected, promise };
};

/**
 * Lazy equivalent of `Array.prototype.filter` for iterables.
 * @param {Iterable} iterable An object conforming to the iterable protocol.
 * @param {Function} predicate Equivalent of `Array.prototype.filter`'s first argument.
 * @param {*} thisArg Value to use as `this` in `predicate`
 */
export function* iterFilter(iterable, predicate, thisArg = undefined) {
    let i = 0;
    for (const value of iterable) {
        if (predicate.call(thisArg, value, i, iterable)) {
            yield value;
        }

        i += 1;
    }
}

/**
 * Lazy equivalent of `Array.prototype.slice` for iterables.
 * @param {Iterable} iterable An object conforming to the iterable protocol.
 * @param {number} start Index of first element to include. Negative values are not supported.
 * @param {number|undefined} end Index of first element to exclude. Negative values are not supported.
 */
export function* iterSlice(iterable, start = 0, end = undefined) {
    if (start < 0 || end < 0) throw RangeError('Negative slicing is not supported');

    const iterator = iterable[Symbol.iterator]();

    for (let i = 0; end === undefined || i < end; i++) {
        const { value, done } = iterator.next();

        if (done) {
            return;
        } else if (start <= i) {
            yield value;
        }
    }
}

/**
 * Downloads a given blob object as a file to the user's device
 * @param {Blob} blob The Blob object for the file
 * @param {String} fileName The name the file should be saved as
 */
export const downloadFileFromBlob = (blob, fileName) => {
    const blobURL = window.URL.createObjectURL(blob);

    try {
        const aTag = document.createElement('a');
        aTag.href = blobURL;
        aTag.download = fileName;
        aTag.click();
    } finally {
        // Make sure we clean up our object URL
        window.URL.revokeObjectURL(blobURL);
    }
};

/**
 * Formats a two-dimensional array as a CSV string
 * @param {string[][]} data A two dimensional array representing rows and columns
 * @returns {string} The input in CSV format
 */
export const formatAsCsv = (data) =>
    data
        .map((fieldRow) =>
            // Wrap each field in quotes. Existing quotes are escaped by repetition (" => "").
            fieldRow.map((field) => `"${field.toString().replaceAll('"', '""')}"`).join(','),
        )
        .join('\n');

/**
 * Parse a date (without time) in YYYY-MM-DD format
 * @param {string} dateString A date string in "YYYY-MM-DD" format
 * @returns {Date} The Date equivalent of the provided value
 */
export function parseIsoDate(dateString) {
    return parse(dateString, 'yyyy-MM-dd', new Date());
}

export function formatIsoDate(date) {
    return format(date, 'yyyy-MM-dd');
}

/**
 * Efficiently find the first _n_ results of an iterable matching a string-based search.
 * @template T
 * @param {Iterable<T>} items Source (unfiltered) list
 * @param {string} searchQuery Case-insensitive search query
 * @param {number} limit Return at most this many results (from the start)
 * @param {((item: T) => string)[]} filterFields Callback functions returning values to search
 * @returns {T[] & {hasMore: boolean}} Matching objects from the input
 */
export const lazySearchFilter = (items, searchQuery, limit, filterFields) => {
    const normalizedQuery = searchQuery.toLocaleUpperCase();

    const filtered = iterFilter(
        items,
        (item) =>
            !normalizedQuery ||
            filterFields.some((fieldFn) =>
                fieldFn(item).toLocaleUpperCase().includes(normalizedQuery),
            ),
    );

    const filteredAndSliced = iterSlice(filtered, 0, limit);

    const result = Array.from(filteredAndSliced);
    result.hasMore = !filtered.next().done;
    return result;
};

/**
 * Group an array as an object with a property for each key.
 * @template T
 * @param {T[]} array - Array of items to group
 * @param {function(T, number, T[]): string} callbackFn - Keying function
 * @returns {Object.<string, T[]>}
 */
export function groupBy(array, callbackFn) {
    const result = Object.create(null);

    for (const [index, element] of array.entries()) {
        const key = callbackFn(element, index, array);
        result[key] ??= [];
        result[key].push(element);
    }

    return result;
}

/**
 * Attempt to produce a formatted version of a phone number.
 * @param {string} phoneNumber - Potentially unformatted phone number.
 * @returns {string} Formatted phone number, or the original string.
 */
export function formatPhoneNumber(phoneNumber) {
    const regex = /^\+?(\d{1,3})?[-.\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;

    const match = phoneNumber.trim().match(regex);
    if (match) {
        const [, country, area, exchange, line] = match;
        return (country ? `+${country} ` : '') + `(${area}) ${exchange}-${line}`;
    } else {
        return phoneNumber;
    }
}

/**
 * Shallow copy an object with only a subset of its keys.
 * @param {object} sourceObj - Source object
 * @param {...string|symbol} keys - Keys to pick
 * @returns {object} Object with only picked keys
 */
export function pick(sourceObj, ...keys) {
    const partialObj = {};

    for (const key of keys) {
        if (Object.hasOwn(sourceObj, key)) {
            partialObj[key] = sourceObj[key];
        }
    }

    return partialObj;
}

/**
 * Shallow copy an object with some of its keys not included.
 * @param {object} sourceObj - Source object
 * @param {...string|symbol} keys - Keys to omit
 * @returns {object} Object without omitted keys
 */
export function omit(sourceObj, ...keys) {
    const omittedKeys = new Set(keys);
    const omittedObj = {};

    for (const key of Object.keys(sourceObj)) {
        if (!omittedKeys.has(key)) {
            omittedObj[key] = sourceObj[key];
        }
    }

    return omittedObj;
}

/**
 * Creates a version of a function that only executes after an amount
 * of time has passed since it was last called.
 * @param {Function} fn - Input function
 * @param {number} delay - Delay in milliseconds
 * @returns {Function} Debounced version of `fn`
 */
export function debounce(fn, delay) {
    let timeout;

    return function (...args) {
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(fn.bind(this, ...args), delay);
    };
}

/**
 * Create a Ref that copies the value of another ref after an amount of time
 * has passed since the other ref was last updated.
 * @template T
 * @param {Ref<T>} valueRef - Input ref
 * @param {number} delay - Delay in milliseconds
 * @returns {Readonly<Ref<T>>} Debounced version of `valueRef`
 */
export function useDebouncedRef(valueRef, delay) {
    const debouncedRef = ref(valueRef.value);

    watch(
        valueRef,
        debounce(() => {
            debouncedRef.value = valueRef.value;
        }, delay),
    );

    return readonly(debouncedRef);
}

/** Returns a Promise that resolves after approximately `ms` milliseconds. */
export function sleep(ms) {
    return new Promise((resolve) => setTimeout(() => resolve(), ms));
}

/**
 * Copies text to the user's clipboard.
 * @param {string} text
 * @returns {Promise<void>}
 */
export async function copyToClipboard(text) {
    await navigator.clipboard?.writeText?.(text);
}

/**
 * Finds the length of a collection
 * @param {string|Array|Set|Map} collection
 * @returns {number}
 */
export function getLengthOf(collection) {
    if (typeof collection === 'string' || Array.isArray(collection)) {
        return collection.length;
    } else if (collection instanceof Set || collection instanceof Map) {
        return collection.size;
    } else {
        throw TypeError('Not a valid collection');
    }
}

/**
 * Allows a bulk checkbox to be used as a Select All checkbox option.
 * @template T
 * @param {Ref<T[]|Set<T>>} selectedRef
 * @param {import('vue').MaybeRef<T[]|Set<T>>} allItemsRef
 */
export function useBulkCheckbox(selectedRef, allItemsRef) {
    const numSelected = computed(() => getLengthOf(unref(selectedRef)));
    const isIndeterminate = computed(
        () => numSelected.value > 0 && numSelected.value < getLengthOf(unref(allItemsRef)),
    );

    const selectAllModel = computed({
        get: () => getLengthOf(unref(selectedRef)) >= getLengthOf(unref(allItemsRef)),
        set: (value) => {
            const newSelection = value ? [...unref(allItemsRef)] : [];
            selectedRef.value = Array.isArray(unref(selectedRef))
                ? newSelection
                : new Set(newSelection);
        },
    });

    return { selectAllModel, isIndeterminate };
}

/**
 * Checks if the given parameter value is blank. This specifically omits 0 number values.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} Returns `true` if the value is blank, else `false`.
 */
export function isParamBlank(value) {
    return value == null || value === '' || (Array.isArray(value) && value.length === 0);
}

/**
 * Creates a ref value that is synchronized with the route query parameter
 *
 * @param {type} paramName - Name of the parameter as it is displayed in the route query
 * @param {type} defaultValue - Default value of the parameter to use if query param is missing
 * @returns {Ref<any>}
 */
export function useRouteQueryParam(paramName, defaultValue) {
    const query = useRouteQuery({ [paramName]: defaultValue });
    return computed({
        get: () => query.value[paramName],
        set: (value) => (query.value[paramName] = value),
    });
}

/**
 * 1. Creates ref object with keys defined by a given config object and values initialized
 *    with either the current route query or config defaults (in that order).
 * 2. Watches returned ref for changes and updates the route query accordingly.
 *
 * @param {MaybeRef<Record<string, any>>} - Config mapping of query parameter keys to their default values
 * if they are missing in the router query
 * @returns {Ref<Record<string, any>>}
 */
export function useRouteQuery(queryConfig) {
    const queryRef = ref({});
    const router = useRouter();

    // Update query when the ref changes
    watch(
        () => queryRef.value,
        (val) => {
            // Initialize with router query values that are not in the original config
            const query = omit(router.currentRoute.value.query, ...Object.keys(unref(queryConfig)));
            // Include values from query ref that are not blank
            for (const [key, value] of Object.entries(val)) {
                if (!isParamBlank(value)) {
                    query[key] = value;
                }
            }
            router.replace({ query });
        },
        { deep: true },
    );

    // Set up initial query values
    Object.entries(unref(queryConfig)).forEach(([key, value]) => {
        // Overwrite default values with router query values that aren't blank
        const queryValue = router.currentRoute.value.query[key];
        if (!isParamBlank(queryValue)) {
            // Normalize router query values that should be arrays
            if (Array.isArray(value) && !Array.isArray(queryValue)) {
                queryRef.value[key] = [queryValue];
            } else {
                queryRef.value[key] = queryValue;
            }
            // Otherwise, assign the query config value if it isn't blank
        } else if (!isParamBlank(value)) {
            queryRef.value[key] = value;
        }
    });

    return queryRef;
}

export function useStickyIsPinned(element) {
    const isPinned = ref(false);
    const observer = new IntersectionObserver(
        ([e]) => {
            isPinned.value = e.intersectionRatio < 1;
        },
        { threshold: [1] },
    );
    const cleanup = () => {
        if (observer) {
            observer.disconnect();
        }
    };
    watchEffect(() => {
        cleanup();
        if (element.value) {
            observer.observe(element.value);
        }
    });
    onUnmounted(cleanup);
    return { isPinned };
}

/**
 * Calculates the bulk price, minimum price in cents, and percent discount for a given SKU.
 * Used to display pricing info on cart item edit/update modals
 *
 * @param {Object} skuInfo - The SKU object. This object should have properties for
 *      `bulk_pricing`, `max_discount_cents`, `customer_price_cents`, and `base_price_cents`.
 * @param {Object} formData - (This object should be Reactive) - The form data object.
 *      This object should have properties for `quantity` and/or `priceDollars`.
 *
 * @returns {Object} An object containing the calculated `bulkPrice`, `minPriceCents`, and `percentDiscount`.
 */
export function useSkuPricing(skuInfo, formData) {
    const bulkPrice = computed(() => {
        if (!skuInfo.bulk_pricing?.length) return null;
        return skuInfo.bulk_pricing.findLast((bulk) => formData.quantity >= bulk.minimum)
            ?.unit_price_cents;
    });

    const minPriceCents = computed(() => {
        // -1 is flag value for unlimited discounts allowed => minPriceCents is then $0
        if (skuInfo.max_discount_cents === -1) {
            return 0;
        }

        const basePriceCents = skuInfo.customer_price_cents;
        const maxDiscountCents = skuInfo.max_discount_cents;

        // Ensure minPrice is never less than 0
        const minPrice = Math.max(0, basePriceCents - maxDiscountCents);
        // Return the bulkPrice if it exists
        return bulkPrice.value != null ? Math.min(minPrice, bulkPrice.value) : minPrice;
    });

    const percentDiscount = computed(() => {
        const original =
            bulkPrice.value ?? skuInfo.customer_price_cents ?? skuInfo.base_price_cents;
        const percentDiscount = ((formData.priceDollars * 100 - original) / original) * 100;

        if (Math.abs(percentDiscount) > 1) {
            return Math.round(percentDiscount);
        } else {
            return Number(percentDiscount.toFixed(2));
        }
    });

    return { minPriceCents, percentDiscount, bulkPrice };
}

/**
 * Remove diacritical marks (accents) from the given string.
 * @param {string} string
 * @returns {string}
 */
export function removeDiacritics(string) {
    return String(string)
        .normalize('NFKD')
        .replace(/\p{Dia}/gu, '');
}

/**
 * Formats the input in bytes using SI suffixes (kB, MB, etc.)
 * @param {number} bytes Number of bytes, e.g. `4200`
 * @returns {string} Formatted byte count, e.g. `'4.2 kB'`
 */
export function formatByteCount(bytes) {
    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'RB', 'QB'];
    const numberFormat = new Intl.NumberFormat('en', {
        maximumFractionDigits: 1,
        maximumSignificantDigits: 3,
        useGrouping: false,
    });

    // The formatter would round 999.95 up to 1000
    while (Math.abs(bytes) >= 999.95 && units.length > 1) {
        units.shift();
        bytes /= 1000;
    }

    return numberFormat.format(bytes) + ' ' + units[0];
}

/**
 * Get the extension (without leading dot) from a filename
 * @param {string} filename The filename
 * @returns {string | null} The extension, or `null` if the filename has no extension.
 */
export function getFileExtension(filename) {
    const lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex > 0 ? filename.slice(lastDotIndex + 1) : null;
}

/**
 * Synchronously open a blank window, then asynchronously update its URL. This
 * should get around popup blockers, so long as this function is called
 * synchronously in a 'click' event handler.
 * @param {Promise<string | URL>} urlPromise Promise resolving to the URL to open
 *  in the new window.
 */
export function openInNewWindowAsync(urlPromise) {
    const newWindow = window.open();

    urlPromise
        .then((targetUrl) => {
            newWindow.location.href = String(targetUrl);
        })
        .catch(() => {
            newWindow.close();
        });
}
