import { inject } from 'vue';
import { resolveAbsoluteURL } from '../router';
import { createAuth0 } from './auth0';

const auth = createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    apiAudience: import.meta.env.VITE_AUTH0_AUDIENCE,
    redirectCallbackUrl: resolveAbsoluteURL({ name: 'authorized-callback' }),
});

export default auth;

/** @returns {typeof auth} */
export function useAuth() {
    return inject('auth');
}
