<script setup>
    import { watch } from 'vue';
    import { useRoute } from 'vue-router';

    const props = defineProps({
        isOpen: { type: Boolean, required: true },
    });

    const emit = defineEmits(['close']);

    const route = useRoute();
    watch(
        () => route.path,
        () => {
            if (props.isOpen) {
                emit('close');
            }
        },
    );
</script>

<template>
    <div class="space-y-1 rounded-lg border-slate-500 bg-slate-700 p-1 md:border">
        <slot />
    </div>
</template>
