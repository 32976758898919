import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
import { Alert, Badge, Button, Modal, Spinner } from '@medshift/ui';
import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import auth from './auth';
import SoftLink from './components/SoftLink.vue';
import router from './router';
import tenant from './tenant';

const app = createApp(App);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    logErrors: true,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
});

app.use(auth);
app.use(router);
app.use(tenant);

// Globally available components (no import required)
app.component('SoftLink', SoftLink);

// Global MedShift UI components
app.component('Alert', Alert);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Modal', Modal);
app.component('Spinner', Spinner);

// Global FontAwesome components
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('FontAwesomeLayers', FontAwesomeLayers);
app.component('FontAwesomeLayersText', FontAwesomeLayersText);

app.mount('#app');
