<script>
    export default {
        inheritAttrs: false,
    };
</script>

<script setup>
    import { computed, inject } from 'vue';
    import { getPermissionsForRoute } from '../router';
    import { omit } from '../utils';
    import { RouterLink } from 'vue-router';

    const { userCan } = inject('auth');

    const props = defineProps({
        /** @type {import('vue').PropType<import('vue-router').RouteLocationRaw>} */
        to: { type: [String, Object], required: true },
    });

    const routerLinkProps = Object.keys(RouterLink.props);

    const routeIsVisible = computed(() => userCan(...getPermissionsForRoute(props.to)));
</script>

<template>
    <RouterLink v-if="routeIsVisible" class="text-link" :to="to" v-bind="$attrs">
        <slot />
    </RouterLink>
    <span v-else v-bind="omit($attrs, ...routerLinkProps)"><slot /></span>
</template>
