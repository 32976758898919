<script setup>
    import { ref, watch } from 'vue';
    import { useAuth } from '../../auth';
    import { useAsyncState } from '../../utils';

    const auth = useAuth();

    const props = defineProps({
        isOpen: { type: Boolean },
    });

    const emit = defineEmits({
        close: (e) => e === undefined,
    });

    const resetRequest = ref();

    function handleReset() {
        resetRequest.value = useAsyncState(auth.resetPassword());
    }

    watch(
        () => props.isOpen,
        () => {
            if (props.isOpen) {
                handleReset();
            } else {
                resetRequest.value = null;
            }
        },
    );

    function tryToClose() {
        if (!resetRequest.value?.isPending) {
            emit('close');
        }
    }
</script>

<template>
    <Modal :is-open="isOpen" @close="tryToClose()">
        <div class="text-sm text-gray-600">
            <p v-if="resetRequest?.isFulfilled">
                We've sent a password change email to <strong>{{ auth.user.value?.email }}</strong>
            </p>
            <p v-else-if="resetRequest?.isRejected">
                An error occurred while trying to send the password change email
            </p>
            <p v-else>Sending password change email to {{ auth.user.value?.email }}</p>
        </div>

        <template #actions>
            <Button
                v-if="resetRequest?.isRejected"
                :disabled="resetRequest?.isPending"
                @click="tryToClose"
            >
                Cancel
            </Button>
            <Button
                v-if="resetRequest?.isRejected"
                @click="handleReset"
                sentiment="primary"
                :disabled="resetRequest?.isPending"
                :loading="resetRequest?.isPending"
            >
                Retry
            </Button>
            <Button
                v-else
                @click="tryToClose"
                sentiment="primary"
                :disabled="resetRequest?.isPending"
                :loading="resetRequest?.isPending"
            >
                Close
            </Button>
        </template>
    </Modal>
</template>
