import auth from '../auth';

export class ResponseError extends Error {
    /** @type {Response} */ response;
    /** @type {any} */ data;

    constructor(...args) {
        super(...args);
        this.name = 'ResponseError';
    }
}

export class RequestCancelled extends Error {
    constructor(...args) {
        super(...args);
        this.name = 'RequestCancelled';
    }
}

export const apiURL = new URL(import.meta.env.VITE_API_URL);
apiURL.hostname = window.location.hostname.split('.')[0] + '.' + apiURL.hostname;

/**
 * @param {Response} response
 * @returns {Promise<Response>}
 */
async function handleErrorResponses(response) {
    if (response.ok) return response;

    const error = new ResponseError(response.statusText);
    error.response = response;

    try {
        switch (response.headers.get('Content-Type')) {
            case 'application/json':
                error.data = await response.clone().json();
                break;
            case 'application/xml':
            case 'text/xml':
                error.data = new DOMParser().parseFromString(
                    await response.clone().text(),
                    'application/xml',
                );
                break;
            default:
                error.data = await response.clone().text();
        }
    } catch {
        error.data = undefined;
    }

    throw error;
}

/**
 * @param {Error} error
 * @returns {never}
 */
function normalizeAbortError(error) {
    if (error instanceof DOMException && error.name === 'AbortError') {
        throw new RequestCancelled(error.message);
    } else {
        throw error;
    }
}

/**
 * Make a request to the Hydra API.
 * @param {string} method HTTP request method ('GET', 'POST', etc.)
 * @param {string} resource Relative endpoint to call
 * @param {Object} [options] Additional options
 * @param {any} [options.body] Request body content, will be JSON encoded by default
 * @param {Object.<string, string>} [options.params] Query parameters expressed as an object
 * @param {Object.<string, string>} [options.headers] Additional HTTP headers to include in the request
 * @param {boolean} [options.json] Controls JSON encoding of the request body
 * @param {boolean} [options.authenticate] Indicates whether the request must be authenticated with Auth0
 */
export function apiFetch(
    method,
    resource,
    { body, params, headers, json = true, authenticate = true } = {},
) {
    const abortController = new AbortController();

    const url = new URL(resource, apiURL);
    Object.entries(params ?? {}).forEach(([key, value]) => {
        if (value !== undefined) {
            url.searchParams.set(key, value);
        }
    });

    const response = (authenticate ? auth.getAccessToken() : Promise.resolve())
        .then((token) =>
            window.fetch(url, {
                method,
                headers: {
                    'Authorization': token ? `Bearer ${token}` : undefined,
                    'Content-Type': json ? 'application/json' : undefined,
                    ...headers,
                },
                body: json ? JSON.stringify(body) : body, // JSON.stringify(undefined) === undefined
                signal: abortController.signal,
            }),
        )
        .then(handleErrorResponses)
        .catch(normalizeAbortError);

    return { response, cancel: () => abortController.abort() };
}

/**
 * Upload a file to an S3 (or R2) bucket via PUT using a presigned URL.
 * @param {string} presignedUrl Presigned absolute URL
 * @param {File} file The file to upload
 */
export function uploadFileToS3(presignedUrl, file) {
    const abortController = new AbortController();

    const url = new URL(presignedUrl);
    if (url.protocol !== 'https:') {
        throw TypeError('Expected value for presignedUrl to use https://');
    }

    const response = window
        .fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,
            },
            body: file,
            signal: abortController.signal,
        })
        .then(handleErrorResponses)
        .catch(normalizeAbortError);

    return { response, cancel: () => abortController.abort() };
}
