<script setup>
    import { computed, inject } from 'vue';
    import { getPermissionsForRoute } from '../../router';

    const { userCan } = inject('auth');

    const props = defineProps({
        name: { type: String, required: true },
        route: { type: null, required: true },
    });

    const canSeeRoute = computed(() => userCan(...getPermissionsForRoute(props.route)));
</script>

<template>
    <RouterLink
        v-if="canSeeRoute"
        :to="route"
        class="block whitespace-nowrap rounded-md px-3 py-2 text-sm font-medium text-slate-100 hover:bg-slate-600 hover:text-white"
        active-class="bg-slate-800"
    >
        {{ name }}
    </RouterLink>
</template>
