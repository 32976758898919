/** Base class for authentication errors */
export class AuthenticationError extends Error {
    constructor(...args) {
        super(...args);
        this.name = 'AuthenticationError';
    }
}

export class NotAuthenticatedError extends AuthenticationError {
    constructor(...args) {
        super(...args);
        this.name = 'NotAuthenticatedError';
    }
}

export class AuthenticationExpiredError extends AuthenticationError {
    constructor(...args) {
        super(...args);
        this.name = 'AuthenticationExpiredError';
    }
}

export class AuthCallbackError extends AuthenticationError {
    constructor(error, description, ...args) {
        super(error, ...args);
        this.description = description;
        this.name = 'AuthCallbackError';
    }
}

export class Auth0AuthenticationError extends Error {
    /** @type {number|undefined} */ statusCode;
    /** @type {string|undefined} */ statusText;
    /** @type {string|undefined} */ code;
    /** @type {string|undefined} */ description;
    /** @type {string|undefined} */ name;
    /** @type {string|undefined} */ policy;

    /**
     * @param {import('auth0-js').Auth0Error} auth0Error
     */
    constructor(auth0Error) {
        super(auth0Error.description ?? String(auth0Error), { cause: auth0Error.original });

        this.statusCode = auth0Error.statusCode ?? undefined;
        this.statusText = auth0Error.statusText ?? undefined;
        this.code = auth0Error.code ?? undefined;
        this.description = auth0Error.description ?? undefined;
        this.name = auth0Error.name ?? undefined;
        this.policy = auth0Error.policy ?? undefined;

        this.name = 'Auth0AuthenticationError';
    }
}
