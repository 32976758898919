<script setup>
    import { Avatar, Dropdown } from '@medshift/ui';
    import { inject, ref } from 'vue';
    import { resolveAbsoluteURL } from '../../router';
    import { useTenant } from '../../tenant';
    import ResetPasswordModal from '../user/ResetPasswordModal.vue';

    const auth = inject('auth');

    const { dbConnection, isLoading, isAuthenticated, user, logInWithRedirect, logOut } =
        inject('auth');
    const { info: tenantInfo } = useTenant();

    // Get the full url of the login page, e.g. http://www.example.com/login
    const absoluteLoginURL = resolveAbsoluteURL({ name: 'login' });

    const login = () => {
        logInWithRedirect({ organization: tenantInfo.value.auth0_org_id });
    };

    const showResetPasswordModal = ref(false);

    const openBigCommerceDashboard = () => {
        window.open(tenantInfo.value.bigcommerce_store_url, '_blank', 'noopener, noreferrer');
    };
</script>

<template>
    <Dropdown.Menu :disabled="isLoading">
        <button
            type="button"
            class="flex rounded-full align-middle focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:ring-offset-slate-700"
        >
            <span class="sr-only">Open user menu</span>
            <Avatar v-if="isLoading" size="sm" class="opacity-30 brightness-0" />
            <Avatar v-else :image="user?.picture" size="sm" class="align-middle" />
        </button>

        <template #items v-if="isAuthenticated">
            <div class="px-4 py-2 text-sm">
                <p>Signed in as</p>
                <p class="truncate font-medium text-gray-900">{{ user?.name || user?.email }}</p>
            </div>
            <div>
                <Dropdown.Item label="BigCommerce Store" @click="openBigCommerceDashboard" />
                <Dropdown.Item
                    v-if="dbConnection"
                    label="Change Password"
                    @click="showResetPasswordModal = true"
                />
                <Dropdown.Item
                    v-if="auth.userCan('read:settings', 'read:reps', 'read:customers')"
                    label="Settings"
                    @click="$router.push({ name: 'settings-customer' })"
                />
                <Dropdown.Item
                    label="Sign Out"
                    @click="logOut({ returnToUrl: absoluteLoginURL })"
                    class="!text-red-600"
                />
            </div>
        </template>
        <template #items v-else>
            <div>
                <Dropdown.Item label="Sign In" :disabled="!tenantInfo" @click="login()" />
            </div>
        </template>
    </Dropdown.Menu>

    <ResetPasswordModal :is-open="showResetPasswordModal" @close="showResetPasswordModal = false" />
</template>
